<template>
    <div>
        <page-header v-if="!hasCreatePermission" pageTitle="ALLOTMENT.INDEX_TITLE" :haveSearch="true" @searching="index($event)"/>
        <page-header v-if="hasCreatePermission" btnNew="ACTIONS.NEW" :haveSearch="true" @searching="index($event)"
                     pageTitle="ALLOTMENT.INDEX_TITLE"
                     @newType="setRegisterModal"/>
        <div class="row gutters" v-if="items && items?.data">
            <div v-for="iten in items?.data" :key="iten.id" class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                <figure class="user-card">
                    <figcaption>
                        <div class="text-right mr-3">
                            <a type="button" class="btn edit-card" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false" style="font-size: 20px" id="listDropdown">
                                <i class="icon-more_vert"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-allotment dropdown-black w-auto"
                                 id="dropdownMenu">
                                <div v-if="iten.url_site" class="dropdown-item pointer text-white"
                                     @click="openMap(iten.url_site)">
                                    <i class="icon-map2 mr-2 font-15"/>{{ t('ACTIONS.MAP') }}
                                </div>
                                <div class="dropdown-item pointer text-white" data-target="#customModalTwo"
                                     data-toggle="modal" data-cy="edit-alotment"
                                     @click="setEditModal(iten.id)" v-if="hasUpdatePermission && hasShowPermission">
                                    <i class="icon-mode_edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                                </div>
                                <div class="dropdown-item pointer text-white" data-target="#customModalTwo"
                                     data-toggle="modal"
                                     v-if="hasShowBrokesPermission" @click="setBrokersModal(iten)">
                                    <i class="icon-people mr-2 font-15"/>{{ t('ALLOTMENT.BROKERS') }}
                                </div>
                                <div class="dropdown-item pointer text-white" @click="showAlert(iten.id)"
                                     v-if="hasDeletePermission && iten.total == 0" data-cy="allotment_index_delete">
                                    <i class="icon-delete mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                                </div>
                                <div class="dropdown-item pointer text-white" @click="copy(iten.id)"
                                     v-if="iten.total > 0"
                                     data-cy="allotment_copy_link">
                                    <i class="icon-copy mr-2 font-15"/>{{ t('GENERAL.MSG.COPY_PUBLIC_LINK') }}
                                </div>
                                <div class="dropdown-item text-white" v-if="hasCreateUserPermission">
                                    <a class=" pointer text-white" href="Modelo para Importação Usuário.xlsx" download>
                                        <i class="icon-arrow-with-circle-down mr-2 font-15"/>{{
                                            t('ALLOTMENT.DOWNLOAD_FILE_EXEMPLE')
                                        }}
                                    </a>
                                </div>
                                <div class="dropdown-item pointer text-white" @click="openUpload(iten.id)"
                                     v-if="hasCreateUserPermission">
                                    <i class="icon-folder_open mr-2 font-15"/>Importar corretores via XLSX
                                </div>
                                <router-link class="dropdown-item pointer text-white"
                                             v-if="hasCreateUserPermission" :to="'/dashboard-loteamento/' + iten.id">
                                    <i class="icon-show_chart mr-2 font-15"/>{{ t('GENERAL.MSG.DASHBOARD') }}
                                </router-link>
                                <router-link class="dropdown-item pointer text-white"
                                             v-if="hasCreateUserPermission" :to="'/cronograma/' + iten.id">
                                    <i class="icon-schedule mr-2 font-15"/>{{ t('Cronograma') }}
                                </router-link>
                            </div>
                        </div>
                        <img alt="Logo Loteamento" style="margin: auto;" class="profile mb-2" :src="iten.image"
                             v-if="iten.image">
                        <h5 :title="iten.name">{{ iten.name.substring(0, 30) }}</h5>
                        <div v-if="hasCreatePermission" style="font-size: 10px; color: #3a424c; text-align: center">{{
                                iten.id
                            }}
                        </div>
                        {{ iten?.cities?.name ?iten?.cities?.name + ' - ' + iten?.cities?.states?.name : ''}}
                    </figcaption>
                    <div v-if="iten.total > 0" style="margin-bottom: -1rem">
                        <div :id="iten.id"></div>
                    </div>
                </figure>
            </div>
        </div>
        <div class="card" v-if="items && !items?.data[0]">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('ALLOTMENT.INDEX_TITLE'), os: t('os')}) }}</h5>
                </div>
            </div>
        </div>

        <div class="row gutters">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items?.data" :items="items" :to="'/loteamento'"
                                      @changePage="index(filter, $event)"></pagination-component>
            </div>
            <div class="col-3" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items?.data">
                    <div class="col-8">
                        <label>Loteamentos por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>


        <upload-modal :uploadFile="uploadFile" @upload="uploadFile = $event"
                      @import="importFile($event)"></upload-modal>

        <modal :modalXl="true" :titleModal="modalTitle" v-if="modalSelected">
            <template v-slot:title>{{ modalTitle }}</template>
            <create-allotment v-if="allotment && modalSelected === '1'" :allotmentIndex="allotment" :isEdit="isEdit"
                              @created="afterCreate" ref="creatAllot"
                              @logo="cropper($event)">
            </create-allotment>
            <show-brokers v-if="modalSelected === '2'" :allotmentIndex="allotment"></show-brokers>
        </modal>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import User from '@/services/user';
import Modal from '@/components/modal';
import logo from '@/../public/logo.png';
import ApexCharts from 'apexcharts';
import widthMd from '../../mixins/widthMd';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Allotments from '@/services/Allotments';
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import ShowBrokers from './showBrokersComponent.vue';
import pageHeader from '@/components/layouts/pageHeader';
import uploadModal from '@/components/layouts/uploadModal';
import CreateAllotment from './createAllotmentComponent.vue';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import jwt_decode from 'jwt-decode';

export default {
    name: 'indexAllotment',
    mixins: [Permission, Validate, widthMd],
    components: {
        Modal,
        pageHeader,
        uploadModal,
        CreateAllotment,
        ShowBrokers,
        PaginationComponent,
        Select2,
    },
    beforeRouteLeave(to, from, next) {
        if (!this.chart) {
            return next();
        } else {
            this.chart.forEach(item => item.destroy());
            this.chart = [];
            return next();
        }
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            perPage: null,
            id: null,
            filter: null,
            name: [],
            chart: [],
            items: null,
            modalSelected: null,
            modalTitle: '',
            allotment: null,
            uploadFile: null,
            allotment_id: null,
            isEdit: null,
            hasShowPermission: false,
            hasIndexPermission: false,
            hasCreatePermission: false,
            hasUpdatePermission: false,
            hasDeletePermission: false,
            hasCreateUserPermission: false,
            publicLot: window.location.host,
            hasShowBrokesPermission: false,
        }
    },

    computed: {
        ...mapState({
            menssageAllotmentRequired: 'menssageAllotmentRequired',
            paginate: 'paginate',
        }),
    },

    mounted() {
        this.$store.commit('changeLoading', true);
        this.index();
        this.fromLots();
        this.startPermissons();
    },

    methods: {
        changePaginate() {
            this.index(this.filter);
        },
        async index(filter = null, page = 1) {
            this.filter = filter;
            this.$store.commit('changeLoading', true);
            await Allotments.index(filter, page, this.perPage).then(resp => {
                if (!resp.data.validations) {
                    this.items = resp.data;
                }
            }).catch(error => {
                this.errorMsg(error)
                this.$store.commit('changeLoading', false);
            });
            this.renderChart();
        },

        renderChart() {
            if (this.chart) {
                this.chart.forEach(item => item.destroy());
                this.chart = [];
            }
            let item = {...this.items?.data};
            for (const key in item) {
                if (item[key]?.total > 0) {
                    let series = [];
                    let label = []
                    Object.keys(item[key].situations).forEach(i => {
                        label.push(item[key].situations[i]);
                        series.push(item[key].counts[i]);
                    })
                    var options = {
                        series: series,
                        colors: item[key].colors,
                        chart: {
                            type: 'donut',
                            height: '185px',
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: false,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    csv: {
                                        filename: `${item[key].name}${key}`,
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                            return new Date(timestamp).toDateString()
                                        }
                                    },
                                    svg: {
                                        filename: `${item[key].name}${key}`,
                                    },
                                    png: {
                                        filename: `${item[key].name}${key}`,
                                    }
                                },
                                autoSelected: 'zoom'
                            },
                        },
                        responsive: [
                            {
                                breakpoint: 920,
                                options: {
                                    chart: {
                                        width: '310',
                                        height: '250px',
                                    },
                                    legend: {
                                        position: 'bottom',
                                        width: '100%',
                                        offsetY: 0,
                                        offsetX: 50,
                                    }
                                }
                            },
                            {
                                breakpoint: 709,
                                options: {
                                    chart: {
                                        width: '305',
                                        height: '270px',
                                    },
                                    legend: {
                                        position: 'bottom',
                                        width: '100%',
                                        offsetY: 0,
                                        offsetX: 50,
                                    }
                                }
                            },
                            {
                                breakpoint: 630,
                                options: {
                                    chart: {
                                        width: '350',
                                        height: '270px',
                                    },
                                    legend: {
                                        position: 'bottom',
                                        width: '100%',
                                        offsetY: 0,
                                        offsetX: 50,
                                    }
                                }
                            },
                            {
                                breakpoint: 1650,
                                options: {
                                    chart: {
                                        width: '325',
                                        height: '270px',
                                    },
                                    legend: {
                                        position: 'bottom',
                                        width: '100%',
                                        offsetY: 0,
                                        offsetX: 50,
                                    }
                                }
                            }],
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            show: true,
                                            formatter: function (val) {
                                                return val.split('(')[0];
                                            }
                                        },
                                        value: {
                                            fontSize: '14px',
                                            show: true
                                        },
                                        total: {
                                            fontSize: '14px',
                                            show: true,
                                            color: '#373d3f',
                                        },
                                    }
                                }
                            }
                        },
                        legend: {
                            show: true,
                            fontSize: '14px',
                            horizontalAlign: 'left',
                            offsetY: 20,
                            offsetX: 0,
                        },
                        labels: label,
                    };
                    let el = document.getElementById(item[key].id);
                    let chart = new ApexCharts(el, options);
                    chart.render();
                    this.chart.push(chart);
                }
            }
            this.$store.commit('changeLoading', false);
        },

        openMap(url) {
            window.open(url, '_blank');
        },

        afterCreate() {
            this.index();
            this.$store.dispatch('ActionCheckAllotments', localStorage.getItem('companyId'));
        },

        setEditModal(allotment_id) {
            sessionStorage.setItem('allotmentId', allotment_id);
            this.modalSelected = '1';
            this.isEdit = true;
            this.$store.commit('changeLoading', true);
            Allotments.show(allotment_id).then(resp => {
                this.allotment = resp.data;
                this.allotment.simulator_config_id = this.allotment.simulator_config_id ? this.allotment.simulator_config_id.toString() : '1';
                this.allotment.public_area_config_id = this.allotment.public_area_config_id ? this.allotment.public_area_config_id.toString() : '1';

                if (resp.data.financing_interest) {
                    this.allotment.financing_interest = parseFloat(this.allotment.financing_interest).toFixed(4);
                }
                if (resp.data.brokerage_value) {
                    this.allotment.brokerage_value = parseFloat(this.allotment.brokerage_value).toFixed(2);
                }
                if (this.allotment?.id_configuracao_bd > 0) {
                    sessionStorage.setItem('idBD', this.allotment.id_configuracao_bd);
                } else {
                    sessionStorage.removeItem('idBD');
                }
                this.modalTitle = this.t('ALLOTMENT.MODAL_TITLE.EDIT');
                this.$store.commit('changeLoading', false);
            }).finally(() => {
                this.$refs.creatAllot.listSimulatorsConfigurations();
                this.$refs.creatAllot.listPublicAreaConfigurations();
            })
        },

        setBrokersModal(allotment) {
            this.modalTitle = allotment.name;
            this.allotment = allotment;
            this.modalSelected = '2';
        },

        setRegisterModal() {
            this.modalSelected = '1';
            this.isEdit = false;
            this.modalTitle = this.t('ALLOTMENT.MODAL_TITLE.ADD');
            this.allotment = {
                name: '',
                image: logo,
                active: true,
                city_id: null,
                latitude: null,
                url_site: '',
                longitude: null,
                description: '',
                is_required_data_client: false,
                financing_interest: null,
                reserve_duration_max: null,
                initial_payment_required: 0,
                financing_calculation_formula: "",
                monetary_correction_indexes_id: null,
                permissions_allotment_on_marketing: false,
                initial_payment_required_is_percentage: true,
                permissions_show_allotment_maps_on_marketing: false,
                permissions_informations_lots_on_marketing: false,
                permissions_financing_simulator_on_marketing: false,
                action_calculate_financing_on_map: false,
                action_reserve_lot_on_map: false,
                action_view_lot_data_on_map: false,
            }
        },

        startPermissons() {
            this.hasShowPermission = this.validatePermissions('Allotment:show');
            this.hasCreateUserPermission = this.validatePermissions('User:create');
            this.hasCreatePermission = this.validatePermissions('Allotment:create') && this.hasPermissionShowBd();
            this.hasUpdatePermission = this.validatePermissions('Allotment:update');
            this.hasDeletePermission = this.validatePermissions('Allotment:delete');
            this.hasShowBrokesPermission = this.validatePermissions('User:create');
        },
        hasPermissionShowBd() {
            const token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },
        showAlert(id) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar'
            }).then(result => {
                if (result.isConfirmed) {
                    if (id == localStorage.getItem('allotmentId')) {
                        localStorage.removeItem('allotmentId');
                    }

                    Allotments.delete(id).then(() => {
                        document.getElementById('closeX').click();
                        this.toast.success(this.t('GENERAL.MSG.DELETED_SUCCESS'))
                        this.$store.dispatch('ActionCheckAllotments', localStorage.getItem('companyId'));
                        this.index()
                    }).catch(error => {
                        this.errorMsg(error)
                        this.$store.commit('changeLoading', false);
                    });
                }
            });
        },

        fromLots() {
            if (this.menssageAllotmentRequired) {
                this.toast.error(this.t('GENERAL.MSG.REQUIRE_ALLOTMENT_REGISTER_FIRST'), {timeout: false})
                this.$store.commit('displayMenssageAllotmentRequired', false);
            }
        },

        copy(allotmentId) {
            localStorage.setItem('publicAllotmentId', allotmentId);
            let link = this.publicLot + '/lote-publico/' + localStorage.getItem('companyId') + '/' + allotmentId
            navigator.clipboard.writeText(link)
                .then(() => this.toast.success('Link de acesso ao lote na area publica foi copiado sucesso'));
        },

        cropper(image) {
            this.$store.commit('setImage', image);
            document.getElementById('imageCropper').click();
        },

        openUpload(id) {
            this.uploadFile = true;
            this.allotment_id = id;
        },

        importFile(file) {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.NOTIFY_CORRETORS_IMPORT'),
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    User.import(file, this.allotment_id, true).then(() => {
                        this.index();
                        this.toast.success(this.t('GENERAL.MSG.USERS_IMPORT_WITH_SUCCESSS'));
                    }).catch(error => this.errorMsg(error)).finally(() => {
                        this.uploadFile = false;
                        this.$store.commit('changeLoading', false);
                    });
                }
                if (result.isDismissed && result.dismiss === 'cancel') {
                    this.$store.commit('changeLoading', true);
                    User.import(file, this.allotment_id).then(() => {
                        this.index();
                        this.toast.success(this.t('GENERAL.MSG.USERS_IMPORT_WITH_SUCCESSS'));
                    }).catch(error => this.errorMsg(error)).finally(() => {
                        this.uploadFile = false;
                        this.$store.commit('changeLoading', false);
                    });
                }
            });
        },
    },
}
</script>

<style>
.right {
    right: 10.5% !important;
}

.tooltip {
    z-index: 1 !important;
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    bottom: 125%;
    left: 78%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

figure.user-card {
    padding: 2rem 1rem 2rem 0rem;
}

.card-focus {
    background: #0e5e4e !important;
    border-bottom: 1px solid #af772b !important;
    opacity: 1 !important;
}

.card-focus i {
    color: #ffffff !important;
}

.dropdown-allotment {
    width: 225px;
    left: 1% !important;
}
</style>
